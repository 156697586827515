<template>
  <div class="noaccess">
    <p>Sorry!!! Currently you don't have access to this page.</p>
    <p>Please contact administrator to get access.</p>
    <p>Thank You.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount } from 'vue'
export default defineComponent({
  setup (props, { emit }) {
    const loading = ref<boolean>(false)
    onBeforeMount(() => {
      emit('updateMenu')
    })
    return {
      loading
    }
  }
})
</script>

<style lang="scss">
.noaccess {
  background-color: #ffffff;
  height: 85vh;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>

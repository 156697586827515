
import { defineComponent, ref, onBeforeMount } from 'vue'
export default defineComponent({
  setup (props, { emit }) {
    const loading = ref<boolean>(false)
    onBeforeMount(() => {
      emit('updateMenu')
    })
    return {
      loading
    }
  }
})
